var coreApp = angular.module('core', []);
function CountryAutocompleteControllerBase($scope, CountryAutocompleteService, listAdditionalParams) {
  $scope.getCountries = function (keywords) {
    var params = [keywords].concat(listAdditionalParams);
    return CountryAutocompleteService.list.apply(CountryAutocompleteService, params).then(function (results) {
      var countries = [];
      angular.forEach(results, function (country) {
        countries.push(country);
      });
      return countries;
    });
  };
  $scope.onCountrySelected = function ($item) {
    $scope.selectedCountry = $item;
  };
  $scope.hasSelectedCountry = function () {
    return _.isObject($scope.selectedCountry) && _.has($scope.selectedCountry, 'name');
  };
  (function init() {
    $scope.selectedCountry = undefined;
    listAdditionalParams = _.isUndefined(listAdditionalParams) ? [] : listAdditionalParams;
  }());
}
function SearchControllerBase($scope, $injector, service, defaultSearchParams, results, config) {
  config = config || {};
  var $timeout = $injector.get('$timeout');
  var SearchParams = $injector.get('SearchParams');
  var currentTimeout = null;
  $scope.isItemsEmpty = function () {
    return $scope.results.count === 0;
  };
  $scope.delete = function (model) {
    service.delete(model).then(function () {
      $scope.runSearch();
    });
  };
  $scope.pageXOfYDisplayText = function () {
    return 'Page ' + $scope.currentPage + ' of ' + $scope.numPages;
  };
  $scope.sortChanged = function (field) {
    $scope.searchParams.sortBy = field;
    $scope.searchParams.sortByDir = $scope.searchParams.sortByDir === 'ASC' ? 'DESC' : 'ASC';
    $scope.runSearch();
  };
  $scope.pageChanged = function () {
    $scope.searchParams.offset = $scope.currentPage * $scope.searchParams.limit - $scope.searchParams.limit;
    $scope.runSearch();
  };
  $scope.filterChanged = function () {
    filterChangedImpl(0);
  };
  $scope.textFilterChanged = function () {
    filterChangedImpl(500);
  };
  var filterChangedImpl = function (delay) {
    $scope.searchParams.offset = 0;
    $scope.currentPage = 1;
    if (currentTimeout) {
      $timeout.cancel(currentTimeout);
    }
    currentTimeout = $timeout(function () {
      $scope.runSearch();
    }, delay);
  };
  $scope.runSearch = function () {
    if (angular.equals($scope.searchParams, defaultSearchParams)) {
      SearchParams.setParams({});
    } else {
      SearchParams.setParams($scope.searchParams);
    }
    service.search($scope.searchParams).then(function (results) {
      $scope.results = results;
      if (_.isFunction($scope.onSearchComplete)) {
        $scope.onSearchComplete();
      }
    });
  };
  var navigateTo = function (path) {
    $injector.get('Navigator').goTo(path);
  };
  var setCurrentPage = function () {
    $scope.currentPage = $scope.searchParams.offset / $scope.searchParams.limit + 1;
  };
  var addNewButton = function (url) {
    window.actionBar.addNewItem({
      clickEvent: function () {
        navigateTo(url);
      }
    });
  };
  var setupConfig = function () {
    var defaultConfig = {
        baseUrl: '',
        showNewButton: true
      };
    config = _.defaults(config, defaultConfig);
  };
  (function init() {
    setupConfig();
    if (!_.isUndefined(window.actionBar)) {
      window.actionBar.reset();
      window.actionBar.addUndoItem({
        label: 'Reset Listing',
        clickEvent: function () {
          $scope.searchParams = defaultSearchParams;
          $scope.runSearch();
        }
      });
      if (config.showNewButton === true) {
        var newUrl = config.baseUrl === '' ? '/new' : '/' + config.baseUrl + '/new';
        addNewButton(newUrl);
      }
    }
    $scope.service = service;
    $scope.searchParams = SearchParams.hasParams() ? SearchParams.getParams() : angular.copy(defaultSearchParams);
    $scope.results = results;
    setCurrentPage();
    $scope.$watch(function () {
      return SearchParams.getParams();
    }, function (newValue, oldValue) {
      if (!angular.equals(newValue, oldValue)) {
        $scope.searchParams = _.defaults(SearchParams.getParams(), defaultSearchParams);
        setCurrentPage();
        $scope.runSearch();
      }
    }, true);
  }());
}
function UserAutocompleteControllerBase($scope, UserAutocompleteService, listAdditionalParams) {
  $scope.getUsers = function (keywords) {
    var params = [keywords].concat(listAdditionalParams);
    return UserAutocompleteService.list.apply(UserAutocompleteService, params).then(function (results) {
      var users = [];
      angular.forEach(results, function (user) {
        users.push(user);
      });
      return users;
    });
  };
  $scope.onUserSelected = function ($item) {
    $scope.selectedUser = $item;
  };
  $scope.hasSelectedUser = function () {
    return _.isObject($scope.selectedUser) && _.has($scope.selectedUser, 'id');
  };
  (function init() {
    $scope.selectedUser = undefined;
    listAdditionalParams = _.isUndefined(listAdditionalParams) ? [] : listAdditionalParams;
  }());
}
angular.module('core').directive('addButton', function () {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: { size: '@' },
    template: '<button type=\'button\' class=\'btn btn-default btn-{{size}}\'><span ng-transclude></span> <i class=\'fa fa-plus\'></i></button>',
    link: function (scope, element, attrs) {
      if (!attrs.size) {
        attrs.size = 'sm';
      }
    }
  };
});
angular.module('core').directive('booleanFilterDropdown', function () {
  return {
    restrict: 'E',
    replace: true,
    scope: {},
    template: '<select ng-options=\'a.value as a.name for a in activeValues\' class=\'form-control\'></select>',
    link: function (scope) {
      scope.activeValues = [
        {
          name: '',
          value: ''
        },
        {
          name: 'Yes',
          value: '1'
        },
        {
          name: 'No',
          value: '0'
        }
      ];
    }
  };
});
angular.module('core').directive('ckEditor', [
  'adminRootUrl',
  function (adminRootUrl) {
    var CKEDITOR_BASEPATH = adminRootUrl + 'application/modules/core/Administration/Assets/Js/ckeditor/';
    return {
      require: '?ngModel',
      link: function (scope, elm, attr, ngModel) {
        var config = { removeButtons: 'Source,Save,Styles,Anchor,Templates,NewPage,Preview,Print,Templates,document,Document,DocProps' };
        var editor = CKEDITOR.replace(elm[0], config);
        editor.on('pasteState', function () {
          scope.$apply(function () {
            ngModel.$setViewValue(editor.getData());
          });
        });
        ngModel.$render = function () {
          editor.setData(ngModel.$modelValue);
        };
      }
    };
  }
]);
angular.module('core').directive('confirmDirtyFormExit', function () {
  return {
    link: function (scope, elem) {
      var formName = elem.attr('name');
      var message = 'You have unsaved changed. Are you sure you want to navigate away from this page?';
      window.onbeforeunload = function () {
        if (scope[formName].$dirty) {
          return message;
        }
      };
      scope.$on('$locationChangeStart', function (event) {
        if (scope[formName].$dirty) {
          if (confirm(message)) {
            scope[formName].$dirty = false;
          } else {
            event.preventDefault();
          }
        }
      });
    }
  };
});
angular.module('core').directive('controlLabel', function () {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {
      width: '@',
      text: '@',
      for: '@',
      helpText: '@'
    },
    require: '?^formGroup',
    template: function (element, attrs) {
      var temp = '<label for=\'{{for}}\' class=\'col-sm-{{width}} control-label\'><span ng-transclude></span>';
      if (attrs.helpText) {
        temp += ' <help-popover text=\'{{helpText}}\'></help-popover>';
      }
      temp += '</label>';
      return temp;
    },
    link: function (scope, element, attrs, formGroup) {
      if (!attrs.width) {
        attrs.width = formGroup ? formGroup.scope.labelWidth : 3;
      }
    }
  };
});
angular.module('core').directive('countryAutocomplete', function () {
  return {
    restrict: 'E',
    replace: true,
    template: function () {
      var temp = '<div>';
      temp += '<input type=\'text\' ng-model=\'selectedCountry\' typeahead=\'country.name for country in getCountries($viewValue)\' typeahead-on-select=\'onCountrySelected($item)\' typeahead-wait-ms=\'500\' class=\'form-control\' placeholder=\'Search countries\'>';
      temp += '</div>';
      return temp;
    }
  };
});
angular.module('core').directive('deleteButton', function () {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      size: '@',
      message: '@',
      action: '&'
    },
    template: '<button ng-click=\'deleteInvoked()\' type=\'button\' class=\'btn btn-danger btn-{{size}}\'><span ng-transclude></span> <i class=\'fa fa-times\'></i></button>',
    controller: [
      '$scope',
      function ($scope) {
        $scope.deleteInvoked = function (itemIndex) {
          admin.confirm($scope.message, function () {
            $scope.action(itemIndex);
          });
        };
      }
    ],
    link: function (scope, element, attrs) {
      if (!attrs.size) {
        attrs.size = 'sm';
      }
      if (attrs.text) {
        attrs.text += ' ';
      } else {
        attrs.text = '';
      }
      if (!attrs.message) {
        attrs.message = 'Are you sure you want to delete this item?';
      }
    }
  };
});
angular.module('core').directive('fieldValidations', [
  '$compile',
  function ($compile) {
    return {
      priority: 1001,
      terminal: true,
      require: '^formGroup',
      link: function (scope, element, attrs, formGroup) {
        element.attr('name', formGroup.scope.fieldName);
        var validations = scope.$eval(attrs.fieldValidations);
        if (validations !== undefined) {
          if (validations.type !== undefined) {
            element.attr('type', validations.type);
          }
          if (validations.required !== undefined) {
            element.attr('ng-required', validations.required);
          }
          if (validations.min !== undefined) {
            element.attr('min', validations.min);
          }
          if (validations.max !== undefined) {
            element.attr('max', validations.max);
          }
          if (validations.minlength !== undefined) {
            element.attr('ng-minlength', validations.minlength);
          }
          if (validations.maxlength !== undefined) {
            element.attr('ng-maxlength', validations.maxlength);
          }
          if (validations.pattern !== undefined) {
            element.attr('ng-pattern', '/^' + validations.pattern + '$/');
          }
        }
        element.removeAttr('field-validations');
        $compile(element)(scope);
      }
    };
  }
]);
angular.module('core').directive('formGroup', function () {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      fieldWidth: '@',
      labelWidth: '@',
      labelText: '@',
      helpText: '@',
      validations: '@',
      fieldName: '@',
      validationErrorMessage: '@'
    },
    require: '^horizontalForm',
    controller: [
      '$scope',
      '$element',
      '$attrs',
      function ($scope, $element, $attrs) {
        this.scope = $scope;
      }
    ],
    template: function (element, attrs) {
      var temp = '<div class=\'form-group\'>';
      if (attrs.labelText) {
        temp += '<control-label width=\'{{labelWidth}}\' help-text=\'{{helpText}}\'>{{labelText}}</control-label>';
      }
      temp += '<div class=\'col-sm-{{fieldWidth}}\' ng-transclude></div>';
      temp += '<div class=\'col-sm-1 form-control-static\'>';
      temp += '<i class=\'fa fa-warning ng-invalid\' ng-show=\'show\' tooltip=\'{{validationErrorMessage}}\'></i>';
      temp += '</div>';
      temp += '</div>';
      return temp;
    },
    link: function (scope, element, attrs, form) {
      if (!attrs.labelWidth) {
        attrs.labelWidth = form ? form.labelWidth : 3;
      }
      if (!attrs.fieldWidth) {
        attrs.fieldWidth = form ? form.fieldWidth : 4;
      }
      form.scope.$watch(form.name + '.' + attrs.fieldName + '.$invalid', function (invalid) {
        scope.show = invalid;
      });
    }
  };
});
angular.module('core').directive('gridDeleteButton', function () {
  return {
    restrict: 'E',
    template: function (element, attrs) {
      return '<delete-button size=\'xs\' action=\'' + attrs.action + '\'></delete-button>';
    }
  };
});
angular.module('core').directive('gridEditLink', function () {
  return {
    restrict: 'E',
    template: function (element, attrs) {
      return '<a href=\'' + attrs.href + '\' tooltip=\'Edit this item\'><i class=\'fa fa-pencil\'></i></a>';
    }
  };
});
angular.module('core').directive('gridFilterColumn', function () {
  return {
    restrict: 'A',
    template: function (element, attrs) {
      var fullParamName = attrs.paramName + 'Filter';
      var standardAttributes = 'ng-model=\'searchParams.' + fullParamName + '\' class=\'form-control\' id=\'' + fullParamName + '\'';
      var temp = '<label class=\'sr-only\' for=\'' + fullParamName + '\'>Filter by ' + attrs.paramName + '</label>';
      if (attrs.type && attrs.type === 'bool') {
        temp += '<boolean-filter-dropdown ng-change=\'filterChanged()\' ' + standardAttributes + '></boolean-filter-dropdown>';
      } else if (attrs.type && attrs.type === 'dropdown') {
        temp += '<select ng-change=\'filterChanged()\' ng-options=\'' + attrs.options + '\' ' + standardAttributes + '>';
        temp += '<option value=\'\'></option>';
        temp += ' </select>';
      } else {
        temp += '<input ng-change=\'textFilterChanged()\' type=\'search\' ' + standardAttributes + ' />';
      }
      return temp;
    }
  };
});
angular.module('core').directive('gridNoItemsAlert', function () {
  return {
    restrict: 'E',
    template: function (element) {
      var temp = '<div ng-show=\'isItemsEmpty()\' class=\'alert alert-info\'>';
      temp += 'No items found';
      temp += '</div>';
      return temp;
    }
  };
});
angular.module('core').directive('gridPagination', function () {
  return {
    restrict: 'E',
    template: function (element) {
      var temp = '<div ng-show=\'!isItemsEmpty()\' class=\'text-center col-md-12 small\'>';
      temp += '<pagination ng-model=\'currentPage\' ng-change=\'pageChanged()\' boundary-links=\'true\' total-items=\'results.count\' items-per-page=\'searchParams.limit\' max-size=\'5\' num-pages=\'numPages\' class=\'pagination\'></pagination>';
      temp += '<br/>';
      temp += '<span ng-bind=\'pageXOfYDisplayText()\' class=\'label label-default\'></span>';
      temp += '</div>';
      temp += '<grid-no-items-alert></grid-no-items-alert>';
      return temp;
    }
  };
});
angular.module('core').directive('gridSortColumn', function () {
  return {
    restrict: 'A',
    template: function (element, attrs) {
      var temp = '<a href=\'\' ng-click="sortChanged(\'' + attrs.paramName + '\')">';
      temp += attrs.label;
      temp += '<span ng-show="searchParams.sortBy == \'' + attrs.paramName + '\'">';
      temp += '<i ng-class="{true: \'fa fa-chevron-up\', false: \'fa fa-chevron-down\'}[searchParams.sortByDir == \'ASC\']"></i>';
      temp += '</span>';
      temp += '</a>';
      return temp;
    }
  };
});
angular.module('core').directive('helpPopover', function () {
  return {
    restrict: 'E',
    scope: { text: '@' },
    template: '<i class=\'fa fa-question-circle\' popover=\'{{text}}\' ng-show=\'text !== ' + '""' + '\' popover-trigger=\'mouseenter\'></i>'
  };
});
angular.module('core').directive('horizontalForm', function () {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: false,
    template: '<form class=\'form-horizontal\' name=\'settingsForm\' confirm-dirty-form-exit ng-transclude></form>',
    controller: [
      '$scope',
      '$element',
      '$attrs',
      function ($scope, $element, $attrs) {
        this.scope = $scope;
        this.name = $attrs.name;
        this.labelWidth = $attrs.labelWidth ? $attrs.labelWidth : 3;
        this.fieldWidth = $attrs.fieldWidth ? $attrs.fieldWidth : 4;
      }
    ]
  };
});
angular.module('core').directive('responsiveGrid', function () {
  return {
    restrict: 'A',
    link: function (scope, element) {
      element.addClass('table table-striped table-hover small');
      element.wrap('<div class=\'table-responsive\'></div>');
    }
  };
});
angular.module('core').value('uiSortableConfig', {}).directive('uiSortable', [
  'uiSortableConfig',
  '$timeout',
  '$log',
  function (uiSortableConfig, $timeout, $log) {
    return {
      require: '?ngModel',
      link: function (scope, element, attrs, ngModel) {
        var savedNodes;
        function combineCallbacks(first, second) {
          if (second && typeof second === 'function') {
            return function (e, ui) {
              first(e, ui);
              second(e, ui);
            };
          }
          return first;
        }
        function hasSortingHelper(element, ui) {
          var helperOption = element.sortable('option', 'helper');
          return helperOption === 'clone' || typeof helperOption === 'function' && ui.item.sortable.isCustomHelperUsed();
        }
        var opts = {};
        var callbacks = {
            receive: null,
            remove: null,
            start: null,
            stop: null,
            update: null
          };
        var wrappers = { helper: null };
        angular.extend(opts, uiSortableConfig, scope.$eval(attrs.uiSortable));
        if (!angular.element.fn || !angular.element.fn.jquery) {
          $log.error('ui.sortable: jQuery should be included before AngularJS!');
          return;
        }
        if (ngModel) {
          // When we add or remove elements, we need the sortable to 'refresh'
          // so it can find the new/removed elements.
          scope.$watch(attrs.ngModel + '.length', function () {
            // Timeout to let ng-repeat modify the DOM
            $timeout(function () {
              // ensure that the jquery-ui-sortable widget instance
              // is still bound to the directive's element
              if (!!element.data('ui-sortable')) {
                element.sortable('refresh');
              }
            });
          });
          callbacks.start = function (e, ui) {
            // Save the starting position of dragged item
            ui.item.sortable = {
              index: ui.item.index(),
              cancel: function () {
                ui.item.sortable._isCanceled = true;
              },
              isCanceled: function () {
                return ui.item.sortable._isCanceled;
              },
              isCustomHelperUsed: function () {
                return !!ui.item.sortable._isCustomHelperUsed;
              },
              _isCanceled: false,
              _isCustomHelperUsed: ui.item.sortable._isCustomHelperUsed
            };
          };
          callbacks.activate = function () {
            // We need to make a copy of the current element's contents so
            // we can restore it after sortable has messed it up.
            // This is inside activate (instead of start) in order to save
            // both lists when dragging between connected lists.
            savedNodes = element.contents();
            // If this list has a placeholder (the connected lists won't),
            // don't inlcude it in saved nodes.
            var placeholder = element.sortable('option', 'placeholder');
            // placeholder.element will be a function if the placeholder, has
            // been created (placeholder will be an object).  If it hasn't
            // been created, either placeholder will be false if no
            // placeholder class was given or placeholder.element will be
            // undefined if a class was given (placeholder will be a string)
            if (placeholder && placeholder.element && typeof placeholder.element === 'function') {
              var phElement = placeholder.element();
              // workaround for jquery ui 1.9.x,
              // not returning jquery collection
              phElement = angular.element(phElement);
              // exact match with the placeholder's class attribute to handle
              // the case that multiple connected sortables exist and
              // the placehoilder option equals the class of sortable items
              var excludes = element.find('[class=\'' + phElement.attr('class') + '\']');
              savedNodes = savedNodes.not(excludes);
            }
          };
          callbacks.update = function (e, ui) {
            // Save current drop position but only if this is not a second
            // update that happens when moving between lists because then
            // the value will be overwritten with the old value
            if (!ui.item.sortable.received) {
              ui.item.sortable.dropindex = ui.item.index();
              ui.item.sortable.droptarget = ui.item.parent();
              // Cancel the sort (let ng-repeat do the sort for us)
              // Don't cancel if this is the received list because it has
              // already been canceled in the other list, and trying to cancel
              // here will mess up the DOM.
              element.sortable('cancel');
            }
            // Put the nodes back exactly the way they started (this is very
            // important because ng-repeat uses comment elements to delineate
            // the start and stop of repeat sections and sortable doesn't
            // respect their order (even if we cancel, the order of the
            // comments are still messed up).
            if (hasSortingHelper(element, ui) && !ui.item.sortable.received) {
              // restore all the savedNodes except .ui-sortable-helper element
              // (which is placed last). That way it will be garbage collected.
              savedNodes = savedNodes.not(savedNodes.last());
            }
            savedNodes.appendTo(element);
            // If received is true (an item was dropped in from another list)
            // then we add the new item to this list otherwise wait until the
            // stop event where we will know if it was a sort or item was
            // moved here from another list
            if (ui.item.sortable.received && !ui.item.sortable.isCanceled()) {
              scope.$apply(function () {
                ngModel.$modelValue.splice(ui.item.sortable.dropindex, 0, ui.item.sortable.moved);
              });
            }
          };
          callbacks.stop = function (e, ui) {
            // If the received flag hasn't be set on the item, this is a
            // normal sort, if dropindex is set, the item was moved, so move
            // the items in the list.
            if (!ui.item.sortable.received && 'dropindex' in ui.item.sortable && !ui.item.sortable.isCanceled()) {
              scope.$apply(function () {
                ngModel.$modelValue.splice(ui.item.sortable.dropindex, 0, ngModel.$modelValue.splice(ui.item.sortable.index, 1)[0]);
              });
            } else {
              // if the item was not moved, then restore the elements
              // so that the ngRepeat's comment are correct.
              if ((!('dropindex' in ui.item.sortable) || ui.item.sortable.isCanceled()) && !hasSortingHelper(element, ui)) {
                savedNodes.appendTo(element);
              }
            }
          };
          callbacks.receive = function (e, ui) {
            // An item was dropped here from another list, set a flag on the
            // item.
            ui.item.sortable.received = true;
          };
          callbacks.remove = function (e, ui) {
            // Workaround for a problem observed in nested connected lists.
            // There should be an 'update' event before 'remove' when moving
            // elements. If the event did not fire, cancel sorting.
            if (!('dropindex' in ui.item.sortable)) {
              element.sortable('cancel');
              ui.item.sortable.cancel();
            }
            // Remove the item from this list's model and copy data into item,
            // so the next list can retrive it
            if (!ui.item.sortable.isCanceled()) {
              scope.$apply(function () {
                ui.item.sortable.moved = ngModel.$modelValue.splice(ui.item.sortable.index, 1)[0];
              });
            }
          };
          wrappers.helper = function (inner) {
            if (inner && typeof inner === 'function') {
              return function (e, item) {
                var innerResult = inner(e, item);
                item.sortable._isCustomHelperUsed = item !== innerResult;
                return innerResult;
              };
            }
            return inner;
          };
          scope.$watch(attrs.uiSortable, function (newVal) {
            // ensure that the jquery-ui-sortable widget instance
            // is still bound to the directive's element
            if (!!element.data('ui-sortable')) {
              angular.forEach(newVal, function (value, key) {
                if (callbacks[key]) {
                  if (key === 'stop') {
                    // call apply after stop
                    value = combineCallbacks(value, function () {
                      scope.$apply();
                    });
                  }
                  // wrap the callback
                  value = combineCallbacks(callbacks[key], value);
                } else if (wrappers[key]) {
                  value = wrappers[key](value);
                }
                element.sortable('option', key, value);
              });
            }
          }, true);
          angular.forEach(callbacks, function (value, key) {
            opts[key] = combineCallbacks(value, opts[key]);
          });
        } else {
          $log.info('ui.sortable: ngModel not provided!', element);
        }
        element.sortable(opts);
      }
    };
  }
]);
angular.module('core').directive('userAutocomplete', function () {
  return {
    restrict: 'E',
    replace: true,
    template: function () {
      var temp = '<div>';
      temp += '<input type=\'text\' ng-model=\'selectedUser\' typeahead=\'user.username for user in getUsers($viewValue)\' typeahead-on-select=\'onUserSelected($item)\' typeahead-wait-ms=\'500\' class=\'form-control\' placeholder=\'Search users\'>';
      temp += '</div>';
      return temp;
    }
  };
});
angular.module('core').filter('dasherise', [
  'Inflector',
  function (Inflector) {
    return function (str) {
      return Inflector.dasherise(str);
    };
  }
]);
angular.module('core').filter('truncate', function () {
  return function (text, length, end) {
    if (isNaN(length)) {
      length = 10;
    }
    if (end === undefined) {
      end = '...';
    }
    if (text.length <= length || text.length - end.length <= length) {
      return text;
    } else {
      return String(text).substring(0, length - end.length) + end;
    }
  };
});
angular.module('core').factory('FieldValidationBuilder', function () {
  var getInstance = function () {
    var intPattern = '\\d+';
    var permalinkPattern = '[a-z0-9-]+';
    var validations = {
        required: true,
        type: 'text'
      };
    var setMessage = function () {
      messages = [];
      if (validations.type === 'text' && validations.required === true) {
        messages.push('Required');
        if (validations.pattern !== undefined && validations.pattern === permalinkPattern) {
          messages.push('Cannot have spaces or special characters other than a dash');
        }
      }
      if (validations.type !== 'text') {
        var typeMessage = validations.type;
        if (validations.type === 'number' && validations.pattern !== undefined && validations.pattern === intPattern) {
          typeMessage = 'integer';
        }
        messages.push('Expected ' + typeMessage + getNumberRangeMessage());
      }
      var lengthRange = getLengthRangeMessage();
      if (lengthRange) {
        messages.push(lengthRange);
      }
      validations.message = messages.join(', ');
    };
    var getNumberRangeMessage = function () {
      var numberRange = [];
      if (validations.min !== undefined) {
        numberRange.push('>= ' + validations.min);
      }
      if (validations.max !== undefined) {
        numberRange.push('<= ' + validations.max);
      }
      return numberRange.length ? ' ' + numberRange.join(' and ') : '';
    };
    var getLengthRangeMessage = function () {
      var lengthRange = [];
      if (validations.minlength !== undefined) {
        lengthRange.push('>= ' + validations.minlength);
      }
      if (validations.maxlength !== undefined) {
        lengthRange.push('<= ' + validations.maxlength);
      }
      return lengthRange.length ? 'length ' + lengthRange.join(' and ') : '';
    };
    return {
      isInteger: function () {
        return this.isNumber().pattern(intPattern);
      },
      isPermalink: function () {
        return this.pattern(permalinkPattern);
      },
      isNotRequired: function () {
        validations.required = false;
        return this;
      },
      isNumber: function () {
        return this.isType('number');
      },
      isEmail: function () {
        return this.isType('email');
      },
      isDate: function () {
        return this.isDate('date');
      },
      isUrl: function () {
        return this.isType('url');
      },
      isType: function (type) {
        validations.type = type;
        return this;
      },
      min: function (min) {
        validations.min = min;
        return this;
      },
      max: function (max) {
        validations.max = max;
        return this;
      },
      minlength: function (minlength) {
        validations.minlength = minlength;
        return this;
      },
      maxlength: function (maxlength) {
        validations.maxlength = maxlength;
        return this;
      },
      pattern: function (pattern) {
        validations.pattern = pattern;
        return this;
      },
      message: function (message) {
        validations.message = message;
        return this;
      },
      get: function () {
        if (validations.message === undefined) {
          setMessage();
        }
        return validations;
      }
    };
  };
  return {
    getInstance: getInstance,
    requiredString: getInstance().get(),
    requiredEmail: getInstance().isEmail().get(),
    requiredPermalink: getInstance().isPermalink().get(),
    requiredInteger: getInstance().isInteger().get(),
    requiredIntegerGreaterThanZero: getInstance().isInteger().min(1).get(),
    requiredUrl: getInstance().isUrl().get()
  };
});
angular.module('core').factory('HttpService', [
  '$http',
  '$q',
  function ($http, $q) {
    var resolving = false;
    var get = function (config) {
      return buildRequestPromise(config);
    };
    var post = function (config) {
      config.method = 'POST';
      return buildRequestPromise(config);
    };
    var put = function (config) {
      config.method = 'PUT';
      return buildRequestPromise(config);
    };
    var doDelete = function (config) {
      config.method = 'DELETE';
      return buildRequestPromise(config);
    };
    var buildRequestPromise = function (config) {
      config = normaliseConfig(config);
      if (config.loader.show && !resolving) {
        zsMessages[config.loader.type](config.loader.message);
      }
      var deferred = $q.defer();
      $http(config).then(function (results) {
        deferred.resolve(results.data);
      }, function (err) {
        if (window.logger) {
          logger.notifyError(err.data);
        }
        zsMessages[config.loader.type](false);
        deferred.reject(err.data);
      }).finally(function () {
        if (config.loader.show && !resolving) {
          zsMessages.loading(false);
        }
      });
      return deferred.promise;
    };
    var normaliseConfig = function (config) {
      var defaultConfig = {
          method: 'GET',
          url: '',
          cache: false,
          loader: {
            show: true,
            type: 'loading',
            message: 'Loading...'
          }
        };
      config.loader = _.defaults(config.loader || {}, defaultConfig.loader);
      return _.defaults(config, defaultConfig);
    };
    var setResolving = function (val) {
      resolving = val;
    };
    return {
      get: get,
      post: post,
      put: put,
      delete: doDelete,
      setResolving: setResolving
    };
  }
]);
angular.module('core').factory('Inflector', function () {
  var dasherise = function (str) {
    return S(str).slugify().s;
  };
  return { dasherise: dasherise };
});
angular.module('core').factory('SearchParams', [
  '$location',
  function ($location) {
    var getParams = function () {
      return $location.search();
    };
    var setParams = function (params) {
      var temp = {};
      angular.forEach(params, function (value, key) {
        if (value !== '' && value !== null) {
          temp[key] = value;
        }
      });
      $location.search(temp);
    };
    var hasParams = function () {
      return !_.isEmpty($location.search());
    };
    return {
      getParams: getParams,
      setParams: setParams,
      hasParams: hasParams
    };
  }
]);
angular.module('core').service('SessionStorage', function () {
  this.put = function (key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  };
  this.get = function (key) {
    return JSON.parse(sessionStorage.getItem(key));
  };
});
angular.module('core').factory('UrlService', [
  '$location',
  function ($location) {
    var getBaseUrl = function () {
      return $location.protocol() + '://' + $location.host() + '/';
    };
    var getCurrentParentUrl = function () {
      return $location.absUrl().substr(0, $location.absUrl().lastIndexOf('/'));
    };
    return {
      getBaseUrl: getBaseUrl,
      getCurrentParentUrl: getCurrentParentUrl
    };
  }
]);